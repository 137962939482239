import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
import CheckboxBox from "./CheckboxBox2";
import ControlledCheckbox from "./ControlledCheckbox";
import { Typography } from "@mui/material";

export default function LeaseCheckbox({ register }) {
  const desktop = useContext(DesktopContext);
  const compact = useContext(CompactContext);

  LeaseCheckbox.propTypes = {
    register: PropTypes.func.isRequired,
  };

  var extendyourlease = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"extendyourlease"}
        checkboxID={"extendyourlease"}
      />
    </>
  );

  var buyyourfreehold = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"buyyourfreehold"}
        checkboxID={"buyyourfreehold"}
      />
    </>
  );

  var righttomanage = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"righttomanage"}
        checkboxID={"righttomanage"}
      />
    </>
  );

  return (
    <>
      {compact && (
        <Typography
          sx={{
            color: "#4F145B !important",
            fontWeight: "500 !important",
            fontSize: desktop ? "1.1rem !important" : "1rem !important",
            mt: desktop ? "1rem !important" : "1rem !important",
            ml: desktop ? "1 !important" : "0 !important",
            mb: desktop ? "0.5rem !important" : "0.5rem !important",
          }}
        >
          Which Services Are You Interested In?
        </Typography>
      )}

      <CheckboxBox
        checkboxes={extendyourlease}
        label={"Extend your lease"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={buyyourfreehold}
        label={"Buy your freehold"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
      <CheckboxBox
        checkboxes={righttomanage}
        label={"Apply for the right to manage"}
        checkboxErrorText={null} // Provide a default value for checkboxErrorText
      />
    </>
  );
}
